import "core-js/modules/es.object.define-property.js";
import { ref } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import addToDesk from './index.js';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    /** ***引入相关包start*****/
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var show = ref(false);
    expose({
      show: show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    //关闭
    var close = function close() {
      show.value = false;
      unMountComponent();
    };

    /** ***函数 end*****/

    /** ***生命周期start*****/
    // 销毁组件
    var unMountComponent = function unMountComponent() {
      addToDesk({
        type: 'unmount'
      });
    };
    /** ***生命周期end*****/

    var __returned__ = {
      t: t,
      show: show,
      close: close,
      unMountComponent: unMountComponent,
      ref: ref,
      MyPopup: MyPopup,
      addToDesk: addToDesk,
      useI18n: useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};