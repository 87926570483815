import "core-js/modules/es.object.define-property.js";
import useAppStore from '@/store/modules/app.js';
export default {
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var appStore = useAppStore();
    var __returned__ = {
      appStore: appStore,
      useAppStore: useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};