import { createVNode as _createVNode, isVNode as _isVNode } from "vue";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import useCopyWriter from '@/store/modules/copywriter';
import useAppStore from '@/store/modules/app';
import { computed } from 'vue';
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export default {
  props: {
    keyWords: {
      type: String,
      require: true,
      "default": ''
    },
    // 返回的html标签类型
    tag: {
      type: String,
      "default": ''
    }
  },
  setup: function setup(props, _ref) {
    var slot = _ref.slot;
    var keyWords = props.keyWords,
      tag = props.tag;
    var copyWriter = useCopyWriter().copyWriterMap;
    var appStore = useAppStore();
    var site = useCopyWriter().site; // yes 多站点  no  普通站点

    var copywritingList = computed(function () {
      return copyWriter === null || copyWriter === void 0 ? void 0 : copyWriter[keyWords].langData[appStore.language];
    });
    return function () {
      var _copywritingList$valu, _copyWriter$keyWords;
      return site === 'yes' ? _createVNode(tag, null, {
        "default": function _default() {
          return [(_copywritingList$valu = copywritingList.value) === null || _copywritingList$valu === void 0 ? void 0 : _copywritingList$valu.map(function (_ref2) {
            var text = _ref2.text,
              field = _ref2.field,
              type = _ref2.type;
            return type === '1' && _createVNode(tag, {
              "key": field
            }, _isSlot(text) ? text : {
              "default": function _default() {
                return [text];
              }
            });
          })];
        }
      }) : _createVNode(tag, null, {
        "default": function _default() {
          return [copyWriter === null || copyWriter === void 0 || (_copyWriter$keyWords = copyWriter[keyWords]) === null || _copyWriter$keyWords === void 0 ? void 0 : _copyWriter$keyWords[appStore.language]];
        }
      });
    };
  }
};