import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import MyLoading from '@/components/MyLoading/index.vue';
import { computed } from 'vue';
export default {
  props: {
    modelValue: {
      type: Boolean,
      "default": false
    },
    //是否开启顶部安全区适配
    safeAreaInsetTop: {
      type: Boolean,
      "default": true
    },
    // 弹窗默认层级
    zIndex: {
      type: Number,
      "default": 2000
    },
    //是否开启底部安全区适配
    safeAreaInsetBottom: {
      type: Boolean,
      "default": true
    },
    //是否在初始渲染时启用过渡动画
    transitionAppear: {
      type: Boolean,
      "default": true
    },
    //指定挂载的节点，等同于 Teleport 组件的 to 属性
    teleport: {
      type: String,
      "default": 'body'
    },
    // 动画过渡时间秒
    duration: {
      type: Number,
      "default": 0.4
    },
    // 进入动画class
    enterAnimateClass: {
      type: String,
      "default": ''
    },
    // 离开动画class
    leaveAnimateClass: {
      type: String,
      "default": ''
    },
    //整个弹窗内容加载是否显示
    containerLoadingShow: {
      type: Boolean,
      "default": false
    },
    // 整个弹窗内容的class样式
    containerClass: {
      type: String,
      "default": ''
    },
    //整个弹窗内容style样式
    containerStyle: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    //默认头部文本
    headerText: {
      type: [String, Number],
      "default": ''
    },
    //默认头部style样式
    headerStyle: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    //弹窗内容加载是否显示
    mainLoadingShow: {
      type: Boolean,
      "default": false
    },
    // 主要内容的class样式
    mainClass: {
      type: String,
      "default": ''
    },
    //主要内容style样式
    mainStyle: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    //默认底部文本
    footerText: {
      type: [String, Number],
      "default": ''
    },
    //默认底部style样式
    footerStyle: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    //按钮加载是否显示
    btnLoadingShow: {
      type: Boolean,
      "default": false
    },
    //关闭按钮是否显示
    closeShow: {
      type: Boolean,
      "default": true
    },
    //默认关闭按钮样式
    closeStyle: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  emits: ['update:modelValue', 'close', 'confirm'],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    expose();
    var props = __props;
    var show = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(value) {
        emit('update:modelValue', value);
      }
    });
    var handleClose = function handleClose() {
      emit('close');
    };
    var handleConfirm = function handleConfirm() {
      emit('confirm');
    };
    var __returned__ = {
      emit: emit,
      props: props,
      show: show,
      handleClose: handleClose,
      handleConfirm: handleConfirm,
      MyLoading: MyLoading,
      computed: computed
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};