import "core-js/modules/es.object.define-property.js";
import { moneyFormat, copyText, formatDateTimer } from '@/utils/index.js';
import MyPopup from '@/components/MyPopup/index.vue';
import rechargeRecordsDetailsPopup from './index.js';
import { ref, onMounted } from 'vue';
export default {
  props: {
    data: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var show = ref(false);
    // 销毁组件
    var unMountComponent = function unMountComponent() {
      rechargeRecordsDetailsPopup({
        type: 'unmount'
      });
    };
    expose({
      show: show
    });
    var __returned__ = {
      show: show,
      unMountComponent: unMountComponent,
      moneyFormat: moneyFormat,
      copyText: copyText,
      formatDateTimer: formatDateTimer,
      MyPopup: MyPopup,
      rechargeRecordsDetailsPopup: rechargeRecordsDetailsPopup,
      ref: ref,
      onMounted: onMounted
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};