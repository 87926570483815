import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4536dee3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "my-popup__loading"
};
var _hoisted_2 = {
  "class": "my-popup__main__content"
};
var _hoisted_3 = {
  key: 0,
  "class": "my-popup__loading"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_button = _resolveComponent("van-button");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createBlock(_component_van_popup, _mergeProps({
    show: $setup.show,
    "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
      return $setup.show = $event;
    })
  }, _ctx.$attrs, {
    duration: $props.duration,
    "safe-area-inset-top": $props.safeAreaInsetTop,
    "safe-area-inset-bottom": $props.safeAreaInsetBottom,
    "transition-appear": $props.transitionAppear,
    teleport: $props.teleport,
    zIndex: $props.zIndex,
    "class": "my-popup",
    "overlay-class": "my-overlay"
  }), {
    "default": _withCtx(function () {
      return [_createElementVNode("div", {
        style: _normalizeStyle($props.containerStyle),
        "class": _normalizeClass(['my-popup__container', $props.containerClass, $setup.show ? $props.enterAnimateClass : $props.leaveAnimateClass])
      }, [_createCommentVNode(" 整个弹窗加载是否显示 "), $props.containerLoadingShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["MyLoading"])])) : _createCommentVNode("v-if", true), _createCommentVNode(" 头部内容 "), _ctx.$slots.header || $props.headerText ? _renderSlot(_ctx.$slots, "header", {
        key: 1
      }, function () {
        return [_createElementVNode("header", {
          style: _normalizeStyle($props.headerStyle),
          "class": "my-popup__header"
        }, [_renderSlot(_ctx.$slots, "header-text", {}, function () {
          return [_createTextVNode(_toDisplayString($props.headerText), 1 /* TEXT */)];
        }, true)], 4 /* STYLE */)];
      }, true) : _createCommentVNode("v-if", true), _createCommentVNode(" 主要内容 "), _createElementVNode("main", {
        style: _normalizeStyle($props.mainStyle),
        "class": _normalizeClass(['my-popup__main', $props.mainClass])
      }, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createCommentVNode(" 内容加载是否显示 "), $props.mainLoadingShow ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode($setup["MyLoading"])])) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */), _createCommentVNode(" 底部内容 "), _ctx.$slots.footer || $props.footerText ? _renderSlot(_ctx.$slots, "footer", {
        key: 2
      }, function () {
        return [_createVNode(_component_van_button, {
          loading: $props.btnLoadingShow,
          style: _normalizeStyle($props.footerStyle),
          "class": "my-popup__footer",
          onClick: $setup.handleConfirm
        }, {
          "default": _withCtx(function () {
            return [_renderSlot(_ctx.$slots, "footer-text", {}, function () {
              return [_createTextVNode(_toDisplayString($props.footerText), 1 /* TEXT */)];
            }, true)];
          }),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["loading", "style"])];
      }, true) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */), _createCommentVNode(" 关闭按钮 "), $props.closeShow && $setup.show ? _renderSlot(_ctx.$slots, "close", {
        key: 0
      }, function () {
        return [_createElementVNode("div", {
          style: _normalizeStyle($props.closeStyle),
          "class": "my-popup__close",
          onClick: $setup.handleClose
        }, null, 4 /* STYLE */)];
      }, true) : _createCommentVNode("v-if", true), _createCommentVNode(" 需要超出容器的内容都应该写在这里，然后使用position 去操作元素的位置而不是将my-popup__container里面的overflow设为visible "), _renderSlot(_ctx.$slots, "other-content", {}, undefined, true), _createCommentVNode(" 蒙层 "), _renderSlot(_ctx.$slots, "overlay-content", {}, undefined, true)];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["show", "duration", "safe-area-inset-top", "safe-area-inset-bottom", "transition-appear", "teleport", "zIndex"]);
}