import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import { myFixed } from '@/utils/index.js';
import useAppStore from '@/store/modules/app.js';
import CellItem from './CellItem.vue';
export default {
  props: {
    list: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    active: {
      type: Number,
      "default": 0
    },
    selectData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    isChecked: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['change'],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    expose();
    var appStore = useAppStore();
    var handleClick = function handleClick(index, item) {
      emit('change', index, item);
    };
    var __returned__ = {
      appStore: appStore,
      emit: emit,
      handleClick: handleClick,
      myFixed: myFixed,
      useAppStore: useAppStore,
      CellItem: CellItem
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};