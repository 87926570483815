import "core-js/modules/es.object.define-property.js";
import "core-js/modules/web.timers.js";
import { computed, ref, onUnmounted } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import useAppStore from '@/store/modules/app.js';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    modelValue: {
      type: Boolean,
      "default": false
    },
    //头部内容
    headerText: {
      type: String,
      "default": ''
    },
    container: {
      type: String,
      "default": ''
    },
    selfContainer: {
      type: Object,
      "default": null
    },
    //左边按钮内容
    leftText: {
      type: String,
      "default": ''
    },
    //右边按钮内容
    rightText: {
      type: String,
      "default": ''
    },
    //关闭
    close: {
      type: Function,
      "default": null
    },
    //左边按钮事件
    leftClickEvent: {
      type: Function,
      "default": null
    },
    //右边按钮事件
    rightClickEvent: {
      type: Function,
      "default": null
    }
  },
  emits: ['update:modelValue', 'close', 'leftClickEvent', 'rightClickEvent'],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    expose();
    var props = __props;
    /** ***引入相关包start*****/

    var appStore = useAppStore();
    var show = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(value) {
        emit('update:modelValue', value);
      }
    });
    var isLoading = ref(false);
    var isLoadingTime = ref(false);

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    var leftClickEvent = function leftClickEvent() {
      isLoading.value = true;
      props.leftClickEvent();
      isLoadingTime.value = setTimeout(function () {
        isLoading.value = false;
      }, 1000);
    };
    var rightClickEvent = function rightClickEvent() {
      props.rightClickEvent();
    };
    var handleClose = function handleClose() {
      props.close();
    };
    var handeleArr = function handeleArr(str) {
      return str === null || str === void 0 ? void 0 : str.split('\n');
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onUnmounted(function () {
      if (isLoadingTime.value) clearTimeout(isLoadingTime.value);
    });
    /** ***生命周期end*****/

    var __returned__ = {
      emit: emit,
      appStore: appStore,
      props: props,
      show: show,
      isLoading: isLoading,
      isLoadingTime: isLoadingTime,
      leftClickEvent: leftClickEvent,
      rightClickEvent: rightClickEvent,
      handleClose: handleClose,
      handeleArr: handeleArr,
      computed: computed,
      ref: ref,
      onUnmounted: onUnmounted,
      MyPopup: MyPopup,
      useAppStore: useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};