import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_list = _resolveComponent("van-list");
  return _openBlock(), _createBlock(_component_van_list, _mergeProps({
    loading: $props.loading,
    "onUpdate:loading": _cache[0] || (_cache[0] = function ($event) {
      return $props.loading = $event;
    }),
    error: $props.error,
    "onUpdate:error": _cache[1] || (_cache[1] = function ($event) {
      return $props.error = $event;
    })
  }, _ctx.$attrs, {
    loadingText: $props.loadingText || _ctx.$t('other.loadingText'),
    finishedText: $props.finishedText || _ctx.$t('other.finishedText'),
    "class": "my-pull-refresh-list"
  }), {
    "default": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["loading", "error", "loadingText", "finishedText"]);
}