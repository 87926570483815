import "core-js/modules/es.object.define-property.js";
import MyPopup from '@/components/MyPopup/index.vue';
import { getMaxZIndex } from '@/utils';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    show: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['agreementShowFun'],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    expose();
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var unMountComponent = function unMountComponent() {
      emit('agreementShowFun');
    };
    var __returned__ = {
      t: t,
      emit: emit,
      unMountComponent: unMountComponent,
      MyPopup: MyPopup,
      getMaxZIndex: getMaxZIndex,
      useI18n: useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};