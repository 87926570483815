import "core-js/modules/es.object.define-property.js";
import showFreeWithdrawSharePopup from '@/views/activity/events/details/components/FreeWithdraw/SharePopup/index.js';
import selectedActivity from '@/hooks/selectedActivity/index';
import router from '@/router';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    handleOk: {
      type: Function,
      // 成功回调
      "default": null
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    /** ***引入相关包start*****/

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    var toDetail = function toDetail() {
      props.handleOk();
      var list = selectedActivity().handleEventActivity('19');
      router.push({
        path: '/eventsDetail',
        query: {
          id: list[0].id,
          type: list[0].activityType
        }
      });
    };
    var toShare = function toShare() {
      props.handleOk();
      showFreeWithdrawSharePopup();
    };
    /** ***函数 end*****/

    var __returned__ = {
      props: props,
      toDetail: toDetail,
      toShare: toShare,
      showFreeWithdrawSharePopup: showFreeWithdrawSharePopup,
      selectedActivity: selectedActivity,
      router: router
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};