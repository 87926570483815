import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-45ee947e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "download-app-nav-module"
};
var _hoisted_2 = {
  "class": "app-name"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$setup$useCopyWriter;
  var _component_a_button = _resolveComponent("a-button");
  return !$setup.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode(" v-if=\"!show && !appStore.hasCloseDownload && !promptStore.notInApp && promptStore.AndroidLink.endsWith('apk')\" "), _createElementVNode("div", _hoisted_2, [_createElementVNode("i", {
    "class": "iconfont icon-31guanbi",
    onClick: $setup.closeFun
  }), $setup.useCopyWriter().site === 'yes' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: (_$setup$useCopyWriter = $setup.useCopyWriter().appInfo) === null || _$setup$useCopyWriter === void 0 || (_$setup$useCopyWriter = _$setup$useCopyWriter.logoList[5]) === null || _$setup$useCopyWriter === void 0 ? void 0 : _$setup$useCopyWriter.url,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_3)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: "/".concat($setup.appStore.themeSystem, "/logo/down-nav-logo.png"),
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_4))]), _createVNode(_component_a_button, {
    type: "primary",
    "class": "default-btn down-btn",
    onClick: $setup.DownloadClick
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('home.downloadApp')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true);
}