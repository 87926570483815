import "core-js/modules/es.object.define-property.js";
import { ref, watch, shallowRef, reactive, onMounted } from 'vue';
import Login from './components/Login/index.vue';
import Register from './components/Register/index.vue';
import { getMaxZIndex } from '@/utils/index';
import useAppStore from '@/store/modules/app.js';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import MyTabs from '@/components/MyTabs';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      "default": false
    },
    type: {
      type: String,
      "default": 'login'
    },
    callback: {
      type: Function,
      // 传入移除节点方法,这里是createApp中的方法
      "default": null
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    /** ***引入相关包start*****/
    var _useI18n = useI18n(),
      t = _useI18n.t;
    // 获取store
    var freeWithdraw = useFreeWithdraw();
    var appStore = useAppStore();

    // 弹出框是否显示
    var pageVisible = ref(false);
    // eslint-disable-next-line vue/no-setup-props-destructure
    pageVisible.value = props.visible; // 把props参数赋值给pageVisible
    // tab切换默认是登录  login  register pwd
    var tabType = ref('login');
    // eslint-disable-next-line vue/no-setup-props-destructure

    var activeIndex = ref(0);
    var tabsList = reactive([{
      com: shallowRef(Register),
      title: t('layout.Register'),
      id: '0',
      iconClass: 'icon-zhucetianjiahaoyou'
    }, {
      com: shallowRef(Login),
      title: t('layout.Login'),
      id: '1',
      iconClass: 'icon-denglu'
    }]);

    /** ***ref、reactive，等……end*****/

    /** ***函数 start*****************/
    var beforeChange = function beforeChange(val) {
      activeIndex.value = val;
    };
    // tab切换
    var tabTypeFun = function tabTypeFun(type) {
      activeIndex.value = type === 'register' ? 0 : 1;
      tabType.value = type;
    };
    // 关闭弹出框
    var closeLogin = function closeLogin() {
      pageVisible.value = false;
    };
    var callback = function callback() {
      if (freeWithdraw.attendStatus === '1' || !freeWithdraw.attendStatus) {
        freeWithdraw.freeWithdrawActivityExecute(true);
      }
      closeLogin();
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(function () {
      tabType.value = props.type;
      activeIndex.value = props.type === 'login' ? 1 : 0;
    });
    // 监听显示的消失，需要移除dom
    watch(function () {
      return pageVisible.value;
    }, function (val) {
      !val && props.callback();
    });
    watch(function () {
      return props.tabType;
    }, function (val) {
      console.log(val, '-----------');
      if (val === 'login') {
        activeIndex.value = 1;
      }
    });
    /** ***生命周期end*****/

    var __returned__ = {
      t: t,
      freeWithdraw: freeWithdraw,
      appStore: appStore,
      props: props,
      pageVisible: pageVisible,
      tabType: tabType,
      activeIndex: activeIndex,
      tabsList: tabsList,
      beforeChange: beforeChange,
      tabTypeFun: tabTypeFun,
      closeLogin: closeLogin,
      callback: callback,
      ref: ref,
      watch: watch,
      shallowRef: shallowRef,
      reactive: reactive,
      onMounted: onMounted,
      Login: Login,
      Register: Register,
      getMaxZIndex: getMaxZIndex,
      useAppStore: useAppStore,
      useFreeWithdraw: useFreeWithdraw,
      MyTabs: MyTabs,
      useI18n: useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};