import "core-js/modules/es.object.define-property.js";
import useAppStore from '@/store/modules/app.js';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    img: {
      type: String,
      "default": ''
    },
    text: {
      type: String,
      "default": ''
    },
    type: {
      type: String,
      "default": 'order' // 默认数据类型为order
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var appStore = useAppStore();
    var _useI18n = useI18n(),
      t = _useI18n.t;

    // 空数据类型，其他空数据类型可以这边定义方便以后更改
    var data = {
      order: {
        img: require("./images/order-empty".concat(appStore.themeSystem === 'theme7119' ? '2' : '', ".png")),
        text: t('other.noRecords')
      }
    };
    var __returned__ = {
      appStore: appStore,
      t: t,
      data: data,
      useAppStore: useAppStore,
      useI18n: useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};