import "core-js/modules/es.object.define-property.js";
import "core-js/modules/web.timers.js";
import { onBeforeUnmount, onMounted, ref } from 'vue';
export default {
  props: {
    color: {
      type: String,
      "default": 'var(--color-primary)'
    },
    // 标题颜色
    titleInactiveColor: {
      type: String,
      "default": 'var(--color-white)'
    },
    // 选中时候标题颜色
    titleActiveColor: {
      type: String,
      "default": 'var(--color-primary)'
    },
    // 选中时候线的宽度
    lineWidth: {
      type: String,
      "default": '30px'
    },
    // 选中时候线的高度
    lineHeight: {
      type: String,
      "default": '2px'
    },
    // 默认设置为收缩默认
    shrink: {
      type: Boolean,
      "default": true
    },
    // 选中时候线是否铺满tab底部（新增）
    lineFill: {
      type: Boolean,
      "default": false
    },
    // 容器头部线是否显示(新增)
    wrapTopLineShow: {
      type: Boolean,
      "default": false
    },
    // 容器底部线是否显示(新增)
    wrapBottomLineShow: {
      type: Boolean,
      "default": false
    },
    // 是否显示箭头点击进行滚动（新增）
    arrowShow: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    var myTabs = ref(null);
    onMounted(function () {
      var tabsEl = myTabs.value.$el;
      var tabsNav = tabsEl.querySelector('.van-tabs__nav');
      arrowShowFn(tabsNav);
      tabsNav.addEventListener('scroll', onTabsNavScroll, true);
      addEventListener('resize', onResize, true);
    });
    onBeforeUnmount(function () {
      var tabsEl = myTabs.value.$el;
      var tabsNav = tabsEl.querySelector('.van-tabs__nav');
      tabsNav.removeEventListener('scroll', onTabsNavScroll, true);
      removeEventListener('resize', onResize, true);
    });

    /* 监听van-tabs__nav滚动条用于判断辅助箭头如何显示 */
    var onTabsNavScroll = function onTabsNavScroll(e) {
      arrowShowFn(e.target);
    };

    /* 监听屏幕改变用于屏幕变化的时候van-tabs__nav滚动条可以滚动到选中的tab */
    var resizeTimer = null;
    var onResize = function onResize() {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
        resizeTimer = null;
      }
      resizeTimer = setTimeout(function () {
        if (!myTabs.value) return;
        var tabsEl = myTabs.value.$el;
        var tabsNav = tabsEl.querySelector('.van-tabs__nav');
        var tabActive = tabsEl.querySelector('.van-tab--active');
        tabsNav.scrollLeft = tabActive.offsetLeft;
      }, 100);
    };

    /* 点击箭头滚动条用于快速滚动到左边或右边*/
    var handleTabsNavScroll = function handleTabsNavScroll(value) {
      if (!myTabs.value) return;
      var tabsEl = myTabs.value.$el;
      var tabsNav = tabsEl.querySelector('.van-tabs__nav');
      tabsNav.scrollTo({
        left: value,
        behavior: 'smooth'
      });
    };

    /* none 不显示箭头，right显示右边箭头,left显示左边箭头，both显示两边箭头 */
    var arrowShowType = ref('none');
    var arrowShowTimer = null;
    var arrowShowFn = function arrowShowFn(tabsNav) {
      if (!props.arrowShow) return;
      if (arrowShowTimer !== null) {
        clearTimeout(arrowShowTimer);
        arrowShowTimer = null;
      }
      arrowShowTimer = setTimeout(function () {
        if (tabsNav.scrollWidth === tabsNav.clientWidth) {
          arrowShowType.value = 'none';
        } else if (tabsNav.scrollLeft === 0) {
          arrowShowType.value = 'right';
        } else if (tabsNav.scrollLeft === tabsNav.scrollWidth - tabsNav.clientWidth) {
          arrowShowType.value = 'left';
        } else {
          arrowShowType.value = 'both';
        }
      }, 100);
    };
    var __returned__ = {
      props: props,
      myTabs: myTabs,
      onTabsNavScroll: onTabsNavScroll,
      resizeTimer: resizeTimer,
      onResize: onResize,
      handleTabsNavScroll: handleTabsNavScroll,
      arrowShowType: arrowShowType,
      arrowShowTimer: arrowShowTimer,
      arrowShowFn: arrowShowFn,
      onBeforeUnmount: onBeforeUnmount,
      onMounted: onMounted,
      ref: ref
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};