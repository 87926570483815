import "core-js/modules/es.object.define-property.js";
import { ref } from 'vue';
export default {
  props: {
    fit: {
      type: String,
      "default": 'cover'
    },
    src: {
      type: String,
      "default": ''
    },
    iconSize: {
      type: String,
      "default": ''
    },
    // 加载类型，
    loadingType: {
      type: String,
      "default": 'default' // 默认default加载是一闪一闪，game是游戏加载 
    },
    // 是否图片懒加载
    lazyLoad: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var loadingShow = ref(true);
    // 加载完成
    var handleLoadingComplete = function handleLoadingComplete() {
      loadingShow.value = false;
    };
    var __returned__ = {
      loadingShow: loadingShow,
      handleLoadingComplete: handleLoadingComplete,
      ref: ref
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};