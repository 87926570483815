import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import { moneyFormat } from '@/utils/index.js';
import useAppStore from '@/store/modules/app.js';
import CellItem from './CellItem.vue';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    list: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    active: {
      type: Number,
      "default": 0
    },
    isChecked: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['change'],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    expose();

    /** ***引入相关包start*****/
    var appStore = useAppStore();

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    var handleClick = function handleClick(index, item) {
      emit('change', index, item);
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/

    /** ***生命周期end*****/

    var __returned__ = {
      appStore: appStore,
      emit: emit,
      handleClick: handleClick,
      moneyFormat: moneyFormat,
      useAppStore: useAppStore,
      CellItem: CellItem
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};