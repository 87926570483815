import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" 移动导航栏 "), $setup.appStore.device === 'mobile' && ($setup.routeInfo.path === '/home' || $setup.routeInfo.path === '/game/house') ? (_openBlock(), _createBlock($setup["NavBarMobile"], {
    key: 0
  })) : _createCommentVNode("v-if", true), _createCommentVNode(" pc导航栏 "), $setup.appStore.device === 'desktop' ? (_openBlock(), _createBlock($setup["NavBarPc"], {
    key: 1
  })) : _createCommentVNode("v-if", true), _createCommentVNode(" 侧边栏 "), _createVNode($setup["SideBarComponent"]), _createCommentVNode(" 右下角 "), _createVNode($setup["NavFloatrComponent"]), _createCommentVNode(" 主体部分 "), _createElementVNode("div", {
    style: _normalizeStyle({
      paddingLeft: "".concat($setup.appStore.device === 'desktop' && $setup.appStore.sideOpen ? '250' : 0, "px")
    }),
    "class": _normalizeClass([{
      'page-container-module': $setup.appStore.device === 'desktop'
    }])
  }, [_createElementVNode("div", {
    "class": _normalizeClass([{
      'page-max-width-wrap': $setup.appStore.device === 'desktop'
    }])
  }, [_createCommentVNode(" pc导航栏 "), $setup.appStore.device === 'desktop' && $setup.routeInfo.meta.navShow.desktop ? (_openBlock(), _createBlock($setup["MyPagePcHeaderComponent"], {
    key: 0,
    title: $setup.routeInfo.meta.inTitle
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createCommentVNode(" 移动导航栏 "), $setup.appStore.device === 'mobile' && $setup.routeInfo.meta.navShow.mobile ? (_openBlock(), _createBlock($setup["MyPageMobileHeaderComponent"], {
    key: 1,
    title: $setup.routeInfo.meta.inTitle
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createVNode(_component_router_view, null, {
    "default": _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))];
    }),
    _: 1 /* STABLE */
  })], 2 /* CLASS */)], 6 /* CLASS, STYLE */), _createCommentVNode(" 移动端页脚 "), $setup.appStore.device === 'mobile' && $setup.routeInfo.meta.navShow.footer ? (_openBlock(), _createBlock($setup["FooterMobileComponent"], {
    key: 2
  })) : _createCommentVNode("v-if", true), _createCommentVNode(" pc端页脚 "), $setup.appStore.device === 'desktop' && ($setup.routeInfo.meta.navShow.footer || $setup.routeInfo.meta.navShow.Pcfooter) ? (_openBlock(), _createBlock($setup["FooterPcComponent"], {
    key: 3
  })) : _createCommentVNode("v-if", true), _createCommentVNode(" 移动端底部导航栏 "), $setup.appStore.device === 'mobile' && $setup.routeInfo.meta.navShow.navigation ? (_openBlock(), _createBlock($setup["TabbarMobileComponent"], {
    key: 4
  })) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}