import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import Bubbles from './bubbles.vue';
export default {
  props: {
    // 是否选中
    active: {
      type: Boolean,
      "default": false
    },
    text: {
      type: [Number, String],
      "default": ""
    },
    size: {
      type: String,
      "default": "normal" //large大号，normal正常，small小号，mini小小号,
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var __returned__ = {
      Bubbles: Bubbles
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};