import "core-js/modules/es.object.define-property.js";
import "core-js/modules/web.timers.js";
import { ref, watch } from 'vue';
import useAppStore from '@/store/modules/app.js';
import usePromptStore from '@/store/modules/beforeinstallprompt';
import useCopyWriter from '@/store/modules/copywriter';
import useSessionCache from '@/hooks/storage/sessionStorage';
import useLocalCache from '@/hooks/storage/localStorage';
export default {
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var _useSessionCache = useSessionCache(),
      sethasCloseDownload = _useSessionCache.sethasCloseDownload,
      gethasCloseDownload = _useSessionCache.gethasCloseDownload;
    var _useLocalCache = useLocalCache(),
      setCloseDownload = _useLocalCache.setCloseDownload;
    // app应用
    var appStore = useAppStore();
    var promptStore = usePromptStore();
    var show = ref(appStore.hasCloseDownload);
    // 下载apk或者pwa安装
    var DownloadClick = function DownloadClick() {
      promptStore.down();
    };
    // 关闭导航栏
    var closeFun = function closeFun() {
      appStore.hasCloseDownload = true;
      sethasCloseDownload(true);
      setCloseDownload(true);
      setTimeout(function () {
        appStore.offsetTopFun();
      }, 20);
    };
    watch(function () {
      return appStore.hasCloseDownload;
    }, function () {
      show.value = gethasCloseDownload();
    });
    var __returned__ = {
      sethasCloseDownload: sethasCloseDownload,
      gethasCloseDownload: gethasCloseDownload,
      setCloseDownload: setCloseDownload,
      appStore: appStore,
      promptStore: promptStore,
      show: show,
      DownloadClick: DownloadClick,
      closeFun: closeFun,
      ref: ref,
      watch: watch,
      useAppStore: useAppStore,
      usePromptStore: usePromptStore,
      useCopyWriter: useCopyWriter,
      useSessionCache: useSessionCache,
      useLocalCache: useLocalCache
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};