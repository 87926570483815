import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  var _component_a_config_provider = _resolveComponent("a-config-provider");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" a-config-provider 这是给ant框架添加国际化 "), _createVNode(_component_a_config_provider, {
    locale: $setup.locale
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}